// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-coach-page-coach-page-tsx": () => import("./../src/templates/CoachPage/CoachPage.tsx" /* webpackChunkName: "component---src-templates-coach-page-coach-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-action-tsx": () => import("./../src/pages/action.tsx" /* webpackChunkName: "component---src-pages-action-tsx" */),
  "component---src-pages-coach-signup-application-tsx": () => import("./../src/pages/coach-signup-application.tsx" /* webpackChunkName: "component---src-pages-coach-signup-application-tsx" */),
  "component---src-pages-discover-tsx": () => import("./../src/pages/discover.tsx" /* webpackChunkName: "component---src-pages-discover-tsx" */),
  "component---src-pages-for-coaches-tsx": () => import("./../src/pages/for-coaches.tsx" /* webpackChunkName: "component---src-pages-for-coaches-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-signin-tsx": () => import("./../src/pages/signin.tsx" /* webpackChunkName: "component---src-pages-signin-tsx" */),
  "component---src-pages-signout-tsx": () => import("./../src/pages/signout.tsx" /* webpackChunkName: "component---src-pages-signout-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-account-tsx": () => import("./../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-pages-vods-tsx": () => import("./../src/pages/vods.tsx" /* webpackChunkName: "component---src-pages-vods-tsx" */)
}

